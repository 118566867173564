import * as React from 'react';

class footer_desc01 extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="footer-block footer-block--contact">
                    <p className="footer-contact_title">
                        <span className="crimson">CONTACT</span>
                        <span className="noto">お問い合わせ</span>
                    </p>
                    <div className="footer-contact-link">
                        <p className="link-tel"><a href="tel:0728244800">
                            <picture>
                                <source media="(max-width: 767px)" srcSet="/assets/img/common/txt_footer01_sp.png" />
                                <img src="/assets/img/common/txt_footer01.png" alt="賃貸、賃貸不動産管理のお問い合わせ" />
                            </picture>
                        </a></p>
                        <p className="link-tel"><a href="tel:0728247777">
                            <picture>
                                <source media="(max-width: 767px)" srcSet="/assets/img/common/txt_footer02_sp.png" />
                                <img src="/assets/img/common/txt_footer02.png" alt="土地開発などお問い合わせ" />
                            </picture>
                        </a></p>
                        <p className="morebtn link-btn noto"><a href="/inquiry_company/"><span>お問い合わせ</span></a></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default footer_desc01;