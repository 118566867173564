import * as React from 'react';

class header_desc02 extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }
    render() {
        return (

            <React.Fragment>
                <div className="nav-drop-wrap">
                    <div className="inner header-list-desc">
                        <p className="list"><a href="/company/overview/" className="hover">会社概要</a></p>
                        <p className="list"><a href="/company/greeting/" className="hover">社長挨拶</a></p>
                    </div>
                </div>
            </React.Fragment>

        )
    }
}
export default header_desc02;