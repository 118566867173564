
import * as React from 'react';
import * as ReactDOM from "react-dom";

import Mobile from "./mobile-header";
import Header_desc01 from "./Header_desc01";
import Header_desc02 from "./header_desc02";

import Footer_desc01 from "./footer_desc01";
import Footer_desc02 from "./footer_desc02";
import Menu from "./menu";

class Header extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="header-compnents noto">
                    <h1 className="site-main-title">
                        <a href="/" className="hover">
                            <img className="mainlogo" src="/assets/img/common/logo.svg" alt="マエダエステート" />
                        </a>
                    </h1>

                    <Menu />

                    <div className="header-compnents-nextlayer">
                        <div className="header-compnents-toplayer">
                            <nav>
                                <ul className="header-compnents-toplayer-list">
                                    <li className=""><a href="/" className="link_anime hover">TOP</a></li>
                                    <li className=""><a href="/outline/" className="link_anime hover">マエダエステートについて</a></li>
                                    <li className="anime js_slidedown">
                                        <a href="/service/" className="link_anime hover"><span className="motion_under">業務内容</span></a>

                                        <Header_desc01 />
                                    </li>
                                    <li className="anime js_slidedown">
                                        <a href="/company/" className="link_anime hover"><span className="motion_under">会社情報</span></a>

                                        <Header_desc02 />
                                    </li>
                                    <li className="contact-btn">
                                        <p><a href="/inquiry_company/"><span>お問い合わせ</span></a></p>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                </div>

                <Mobile />

            </React.Fragment>
        );
    }
}
ReactDOM.render(<Header />, document.getElementById("global-header"));


class Footer extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <p id="pagetop" className="pagetop"><a href="#"><img src="/assets/img/common/btn-pagetop.png" alt="TOP" /></a></p>
                <div className="footer-top">
                    <div className="components-inner">
                        <Footer_desc01 />
                        <div className="footer-block footer-block--link">
                            <Footer_desc02 />
                        </div>
                    </div>
                </div>
                <div className="footer-btm">
                    <div className="components-inner">
                        <ul className="footer-btm-link">
                            <li><a href="/sitemap/"><span>サイトマップ</span></a></li>
                            <li><a href="/privacy/"><span>個人情報保護方針</span></a></li>
                            <li><a href="/link/"><span>リンク集</span></a></li>
                        </ul>
                        <p className="footer-btm-copyright">copyright © Maedaestate. All rights reserved.</p>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
ReactDOM.render(<Footer />, document.getElementById("global-footer"));