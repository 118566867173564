import * as React from 'react';
class Mobile extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="mobail-header-compnents overlay" id="menu_overlay">
                    <div className="mobail-header-inner">
                        <nav>
                            <ul className="mobail-header-link">
                                <li className="mobail-header-link-item delay"><a href="/" className="link_anime">マエダエステートTOP</a></li>
                                <li className="mobail-header-link-item delay"><a href="/outline/" className="link_anime">マエダエステートについて</a></li>
                                <li className="mobail-header-link-item delay">
                                    <p className="clickEvent">
                                        <a href="/service/" className="link_anime">業務内容</a>
                                    </p>
                                    <div className="nav-drop">
                                        <ul className="nav-drop_list">
                                            <li className="list"><a href="/service/" className="hover">業務内容TOP</a></li>
                                            <li className="list"><a href="/service/development/" className="hover">デベロップメント事業</a></li>
                                            <li className="list"><a href="/service/intermediation/" className="hover">不動産仲介事業</a></li>
                                            <li className="list"><a href="/service/sublease/" className="hover">サブリース事業</a></li>
                                            <li className="list"><a href="/service/lease/" className="hover">賃貸物件一覧</a></li>
                                            <li className="list"><a href="/service/buy_sell/" className="hover">売買物件一覧</a></li>
                                            <li className="list"><a href="/service/apartment_management/" className="hover">賃貸不動産管理</a></li>
                                            <li className="list"><a href="/service/akiyaservice/" className="hover">空き家管理(借上)サービス</a></li>
                                            <li className="list"><a href="/service/parking/" className="hover">駐車場管理</a></li>
                                            <li className="list"><a href="/service/bulk_rental/" className="hover">一括借り上げ</a></li>
                                            <li className="list"><a href="/service/management_property/" className="hover">賃貸不動産管理物件一覧</a></li>
                                            <li className="list"><a href="/service/parking_list/" className="hover">駐車場管理物件一覧</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="mobail-header-link-item delay">
                                    <p className="clickEvent">
                                        <a href="/company/" className="link_anime">会社情報</a>
                                    </p>
                                    <div className="nav-drop">
                                        <ul className="nav-drop_list">
                                            <li className="list"><a href="/company/" className="hover">会社情報TOP</a></li>
                                            <li className="list"><a href="/company/overview/" className="hover">会社概要</a></li>
                                            <li className="list"><a href="/company/greeting/" className="hover">社長挨拶</a></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="contact-btn"><p><a href="/inquiry_company/"><span>お問い合わせ</span></a></p></li>
                            </ul>
                        </nav>
                        <div id="sp_global_menu"></div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}
export default Mobile;