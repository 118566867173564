import * as React from 'react';

class footer_desc02 extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <ul className="footer-nav-list footer-nav-list01">
                    <li>
                        <p><a href="/"><span>マエダエステートTOP</span></a></p>
                    </li>
                    <li>
                        <p><a href="/outline/"><span>マエダエステートについて</span></a></p>
                    </li>
                </ul>
                <ul className="footer-nav-list footer-nav-list02">
                    <li>
                        <p className="clickEvent"><a href="/service/"><span>業務内容</span></a></p>
                        <div className="nav-drop event_with_element">
                            <ul className="nav-drop_list">
                                <li className='spObj'><p><a href="/service/"><span>業務内容TOP</span></a></p></li>
                                <li><p><a href="/service/development/"><span>デベロップメント事業</span></a></p></li>
                                <li><p><a href="/service/apartment_management/"><span>賃貸不動産管理</span></a></p></li>
                                <li><p><a href="/service/sublease/"><span>サブリース事業</span></a></p></li>
                                <li><p><a href="/service/parking/"><span>駐車場管理</span></a></p></li>
                                <li><p><a href="/service/intermediation/"><span>不動産仲介事業</span></a></p></li>
                                <li><p><a href="/service/bulk_rental/"><span>一括借り上げ</span></a></p></li>
                                <li><p><a href="/service/lease/"><span>賃貸物件一覧</span></a></p></li>
                                <li><p><a href="/service/akiyaservice/"><span>空き家管理(借上)サービス</span></a></p></li>
                                <li><p><a href="/service/buy_sell/"><span>売買物件一覧</span></a></p></li>
                                <li><p><a href="/service/management_property/"><span>賃貸不動産管理物件一覧</span></a></p></li>
                                <li><p><a href="/service/parking_list/"><span>駐車場管理物件一覧</span></a></p></li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <ul className="footer-nav-list footer-nav-list03">
                    <li>
                        <p className="clickEvent"><a href="/company/"><span>会社情報</span></a></p>
                        <div className="nav-drop event_with_element">
                            <ul className="nav-drop_list">
                                <li className='spObj'><p><a href="/company/"><span>会社情報TOP</span></a></p></li>
                                <li><p><a href="/company/overview/"><span>会社概要</span></a></p></li>
                                <li><p><a href="/company/greeting/"><span>社長挨拶</span></a></p></li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <div className="footer-info">
                    <p className="footer-info-logo"><a href="/"><img src="/assets/img/common/footer_logo.svg" alt="株式会社マエダエステート" /></a></p>
                    <p className="footer-info-address">〒572-8505 大阪府寝屋川市大成町1-1</p>
                    <p className="footer-info-takken">宅建登録： 大阪府知事免許(5)第47613号</p>
                </div>
            </React.Fragment>
        );
    }
}
export default footer_desc02;