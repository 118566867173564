import * as React from 'react';

class Menu extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="menu-components spObj" id="js-menu-toggle">
                    <span></span>
                    <span></span>
                </div>
            </React.Fragment>
        );
    }
}
export default Menu;