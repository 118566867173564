import * as React from 'react';

class header_desc01 extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="nav-drop-wrap">
                    <div className="inner">
                        <div className="service-list">
                            <p className="list-title">土地開発</p>
                            <ul className="definition-list">
                                <li className="list"><a href="/service/development/" className="hover">デベロップメント事業</a></li>
                                <li className="list"><a href="/service/sublease/" className="hover">サブリース事業</a></li>
                                <li className="list"><a href="/service/intermediation/" className="hover">不動産仲介事業</a></li>
                                <li className="list"><a href="/service/lease/" className="hover">賃貸物件一覧</a></li>
                                <li className="list"><a href="/service/buy_sell/" className="hover">売買物件一覧</a></li>
                            </ul>
                        </div>
                        <div className="service-list service-list--second">
                            <p className="list-title">不動産管理</p>
                            <ul className="definition-list">
                                <li className="list"><a href="/service/apartment_management/" className="hover">賃貸不動産管理</a></li>
                                <li className="list"><a href="/service/parking/" className="hover">駐車場管理</a></li>
                                <li className="list"><a href="/service/bulk_rental/" className="hover">一括借り上げ</a></li>
                                <li className="list"><a href="/service/akiyaservice/" className="hover">空き家管理(借上)<br />サービス</a></li>
                                <li className="list"><a href="/service/management_property/" className="hover">賃貸不動産管理物件一覧</a></li>
                                <li className="list"><a href="/service/parking_list/" className="hover">駐車場管理物件一覧</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default header_desc01;